/* Login background style */
.login-container {
    height: 100vh;
    max-width: 100vw !important;
    background-color: #343B40;
}

/* Login form box style */
#login-form {
    padding: 1rem 2rem;
    margin: 0;
    width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: lightgoldenrodyellow;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 2px 2px 8px black;
    border-radius: 10px;
}

/* Login input field style */
.MuiFormControl-root.login-input {
    width: 100%;
    margin-bottom: 1.2rem;
}

/* Forgot password text style */
.MuiTypography-root.forgot-password-link {
    display: block;
    margin-left: auto;
    text-align: right;
}
.MuiTypography-colorPrimary.forgot-password-link {
    color: brown;
}

/* Login button style */
#login-btn {
    display: block;
    width: 100%;
    margin: 2rem 0 2rem 0;
    color: white;
    background-color: #ce9963;
}

@media (max-width: 479px) {
    #login-form {
        width: 60vw;
    }
    #login-form h1 {
        font-size: 1.5rem;
        text-align: center;
    }
    .login-container.unauthorized #login-form {
        width: 70vw;
    }
}
@media (min-width: 480px) {
    /* Error Page Style */
    .login-container.unauthorized .MuiButton-root {
        display: inline;
        margin-left: 0;
        margin-right: 0;
    }
    .login-container.unauthorized .unauthorized-logout.MuiButton-root {
        float: right;
    }
}
@media (max-width: 1023px) and (orientation: landscape) {
    #login-form {
        padding: 0 2rem;
    }
}