.sidebar {
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 105;
    top: 0;
    left: 0;
    box-shadow: 5px 5px 5px gray;
    overflow-x: hidden;
    overflow-y: auto;
    color: #CE9963;
    background-color: #343A40;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
    display: none;
}

.sidebar-header {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    background-color: #343A40 !important;
}

.sidebar .sidebar-item .MuiSvgIcon-root {
    pointer-events: none;
}

/* Sidebar item & sub-item style */
.MuiListItem-button.sidebar-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #CE9963;
}
.sidebar-subitem {
    color: #CE9963 !important;
}
.MuiListItem-button.sidebar-item:hover,
.sidebar-subitem:hover {
    color: black !important;
    background-color: #CE9963 !important;
}
.MuiListItem-button.sidebar-item:hover .MuiListItemIcon-root.sidebar-icon {
    color: black;
}
.sidebar-item-text .MuiTypography-root {
    font-size: large;
    font-weight: bold;
}
.MuiListItemIcon-root.sidebar-icon {
    color: #CE9963;
    margin-right: -15px;
}

/* Style for current active link */
.active-link .sidebar-item,
.active-link .sidebar-subitem {
    color: black !important;
    background-color: #CE9963 !important;
}
.active-link .MuiListItemIcon-root.sidebar-icon {
    color: black;
}

@media (max-width: 479px) {
    .sidebar {
        width: 60%;
    }
    .sidebar .sidebar-item .MuiSvgIcon-root {
        font-size: 1.2rem;
    }
    .sidebar-item-text .MuiTypography-root {
        font-size: 1rem;
    }
    .MuiCollapse-container .MuiListItemText-root .MuiTypography-displayBlock {
        font-size: 0.9rem;
    }

    .sidebar {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
    }
    .slide-in {
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
    }
    .slide-out {
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards;
    }

    /* Animation for sidebar in & out */
    @keyframes slide-in {
        100% { transform: translateX(0%); }
    }
    @-webkit-keyframes slide-in {
        100% { -webkit-transform: translateX(0%); }
    }   
    @keyframes slide-out {
        0% { transform: translateX(0%); }
        100% { transform: translateX(-100%); opacity: 0;}
    }
    @-webkit-keyframes slide-out {
        0% { -webkit-transform: translateX(0%); }
        100% { -webkit-transform: translateX(-100%); }
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .sidebar {
        width: 50%;
    }
    .sidebar .sidebar-item .MuiSvgIcon-root {
        font-size: 1.2rem;
    }
    .sidebar-item-text .MuiTypography-root {
        font-size: 1rem;
    }
    .MuiCollapse-container .MuiListItemText-root .MuiTypography-displayBlock {
        font-size: 0.9rem;
    }

    .sidebar {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
    }
    .slide-in {
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
    }
    .slide-out {
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards;
    }

    /* Animation for sidebar in & out */
    @keyframes slide-in {
        100% { transform: translateX(0%); }
    }
    @-webkit-keyframes slide-in {
        100% { -webkit-transform: translateX(0%); }
    }   
    @keyframes slide-out {
        0% { transform: translateX(0%); }
        100% { transform: translateX(-100%); opacity: 0;}
    }
    @-webkit-keyframes slide-out {
        0% { -webkit-transform: translateX(0%); }
        100% { -webkit-transform: translateX(-100%); }
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .sidebar {
        width: 200px;
    }
    .sidebar-item-text .MuiTypography-root {
        font-size: inherit;
    }
}