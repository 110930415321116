/* Color Variables */
:root {
    --main-brown: #CE9963;
}

/* Style for default link */
.default-link-style {
    color: white;
    text-decoration: none;
}

/* Make item full width */
.full-width {
    width: 100%;
}

/* Style for margin */
.add-margin-bottom {
    margin-bottom: 25px !important;
}
.add-margin-top {
    margin-top: 25px !important;
}
.no-margin-bottom {
    margin-bottom: 0 !important;
}

/* Error style for required field */
.text-required {
    color: red;
    margin-top: -22px;
    text-align: left;
    margin-bottom: 1.2rem;
}
.text-required-half {
    color: red;
    margin-top: -17px;
    float: left;
    margin-bottom: 1.2rem;
}
.text-required-half.half-right {
    margin-left: 5%;
    margin-bottom: 1.2rem;
}
.selection-required {
    margin-top: -5px;
}
.MuiFormLabel-root.Mui-focused {
    color: black !important;
}

/* Style for add & cancel button */
.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary {
    background-color: var(--main-brown);
    color: black;
}
.add-new-btn.MuiButton-root {
    margin-top: 50px;
    font-weight: bold;
    background-color: var(--main-brown);
    min-width: 120px;
}
.add-btn-table.MuiButton-root:hover,
.add-new-btn.MuiButton-root:hover {
    background-color: antiquewhite !important;
}
.add-cancel-btn.MuiButton-root {
    margin-top: 50px;
    font-weight: bold;
    float: right;
    min-width: 120px;
}
.add-btn-table.MuiButton-root {
    font-weight: bold;
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* Dashboard content style */
.content {
    min-height: 100vh;
    margin-left: 300px;
    background-color: lightgray;
}
.grid-container {
    padding: 50px;
}

/*Table*/
.material-table .MuiToolbar-root {
    padding: 0;
}
.material-table .MuiToolbar-root .MuiTypography-h6 {
    padding-left: 15px;
}
.material-table .MuiToolbar-root [class*="MTableToolbar-searchField"],
.material-table .MuiToolbar-root [class*="jss"].MuiFormControl-root {
    padding-right: 15px;
}
.MuiTable-root thead tr th {
    font-weight: bold;
    text-align: left !important;
}
.MuiToolbar-regular {
    background-color: var(--main-brown);
}
.MuiTableCell-paddingCheckbox:last-child {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.table-preview-img {
    max-width: 100px;
    height: 100px;
    display: flex;
}
.table-preview-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    min-height: 100px;
}
.table-footer.MuiGrid-root {
    margin-top: -44px;
    padding-left: 15px;
}
.table-footer.table-dashboard.MuiGrid-root {
    padding-left: 20px;
}
[class*="MTableToolbar-searchField"] .MuiInputBase-root.MuiInput-root.MuiInput-underline,
[class*="jss"].MuiFormControl-root .MuiInputBase-root.MuiInput-root.MuiInput-underline {
    background-color: white;
    padding-left: 10px;
    border-radius: 3px;
}
[class*="MTableToolbar-searchField"] .MuiInput-underline::before, [class*="jss"].MuiFormControl-root
.MuiInput-underline::before,
[class*="MTableToolbar-searchField"] .MuiInput-underline::after, [class*="jss"].MuiFormControl-root
.MuiInput-underline::after {
    border: none;
}
.MuiTableFooter-root .MuiTableCell-footer .MuiToolbar-gutters {
    background-color: #CE9963BB;
}
.material-table .no-image-text {
    padding-top: 25px;
    padding-bottom: 25px;
}

/* Style for all classes that contain "Component-horizontalScrollContainer" */
/* [class^="Component-horizontalScrollContainer"] {
    height: 52vh;
} */
.Component-horizontalScrollContainer-12::-webkit-scrollbar {
    /* [CHROME] width of the entire scrollbar */
    width: 7px;
}
.Component-horizontalScrollContainer-12::-webkit-scrollbar-track {
    /* [CHROME] color of the tracking area */
    background: #F0F0F0;
}
.Component-horizontalScrollContainer-12::-webkit-scrollbar-thumb {
    background-color: var(--main-brown);    /* [CHROME] color of the scroll thumb */
    border-radius: 20px;            /* [CHROME] roundness of the scroll thumb */
}
#prev-event-page .MuiTableCell-paddingNone:last-child div {
    padding-left: 12px;
}

/* Status & Membership cell in table */
.status-bar-active, .status-bar-inactive, .status-bar-registered, .status-bar-suspended,
.membership-bar-honorary, .membership-bar-silver, .membership-bar-platinum,
.level-bar-bronze, .level-bar-silver, .level-bar-gold, .level-bar-platinum {
    margin: 0;
    padding: 5px 20px;
    width: auto;
    min-width: 55%;
    text-align: center;
    border-radius: 10px;
    color: white;
}
.status-bar-active {
    background-color: #75cc5e;
}
.status-bar-inactive {
    background-color: #de6666;
}
.status-bar-registered {
    background-color: var(--main-brown);
}
.status-bar-suspended {
    background-color: grey;
}
.membership-bar-honorary {
    background-color: #dca671;
}
.membership-bar-silver {
    background-color: #c0c5b8;
}
.membership-bar-platinum {
    background-color: #a0b2ca;
}

/* Level cell in table */
.level-bar-bronze {
    background-color: #dca671;
}
.level-bar-silver {
    background-color: #c0c0c0;
}
.level-bar-gold {
    background-color: #ffd700;
}
.level-bar-platinum {
    background-color: #a0b2c6;
}

/* Change color default checked color for Radio Button & Choicebox */
.MuiRadio-colorSecondary.Mui-checked,
.MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--main-brown) !important;
}

/* Style for notification alert */
.notification-alert div div {
    background-color: white;
    padding: 5px 10px;
    border-radius: 10px;
}
.notification-alert div .col-11 {
    border: solid;
}
.notification-alert div div button.close {
    background: none;
    border: none;
    font-size: 2rem;
}
.notification-alert div div span h4 {
    margin-top: 0;
    margin-bottom: 0.8rem;
    text-align: center;
}

/* Style for edit button in detail page */
.edit-btn.MuiButton-root {
    background-color: var(--main-brown);
    font-weight: bold;
    margin-top: 1.5rem;
    float: right;
}
.edit-btn.MuiButton-root:hover {
    background-color: burlywood;
}

/* Style to hide element */
.hide {
    display: none !important;
}

/* Style when page editing is disabled */
.disabled-border:hover {
    border-color: rgba(0, 0, 0, 0.28) !important;
}
.MuiInputBase-root.Mui-disabled,
.MuiFormControlLabel-label.Mui-disabled {
    color: black !important;
}

/* Style for switch / toggle button */
.MuiSwitch-colorSecondary.Mui-checked {
    color: var(--main-brown) !important;
}
.MuiSwitch-track {
    background-color: var(--main-brown) !important;
}

/* Color Style */
.secondary-btn.MuiButtonBase-root {
    background-color: transparent;
    border: solid 2px var(--main-brown);
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}
.red-bg {
    background-color: #dc004e !important;
}
.red-bg:hover {
    background-color: #e33371 !important;
}
.white-text {
    color: white !important;
}

/* Burger button style */
.responsive-header h2 {
    display: none;
}
.menu-icon-btn {
    display: none;
}

/* JWT error style */
.jwt-title {
    text-align: center;
}
.jwt-text {
    text-align: center;
}
.MuiButtonBase-root.jwt-btn {
    display: block;
    margin: 1.5rem auto 1rem;
    background: var(--main-brown);
    color: white;
    font-weight: bold;
}
.MuiButtonBase-root.jwt-btn:hover {
    background: burlywood;
}

/* Download Button Style */
.download-btn.MuiButton-root {
    margin-left: auto;
    font-weight: bold;
    background: var(--main-brown);
}
.download-btn.MuiButton-root:hover {
    background: burlywood;
}

/* Page Not Found Style */
.page-not-found h1 {
    text-align: center;
    font-size: 5rem;
    margin: 1rem 0 0;
}
.page-not-found h2 {
    text-align: center;
    margin-top: 0;
}
.page-not-found .MuiButton-contained {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    background: var(--main-brown);
}
.page-not-found .MuiButton-contained:hover {
    background: burlywood;
}

/* Autocomplete */
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    font-size: 14px;
}

@media (max-width: 479px) {
    .grid-container {
        padding: 30px 30px;
    }
    .responsive-header h2 {
        display: inherit;
    }
    .responsive-header.MuiGrid-container {
        color: var(--main-brown);
        background-color: #343A40;
    }
    .responsive-header h2 {
        margin-left: 30px;
    }
    .menu-icon-btn.MuiGrid-item {
        display: flex;
        align-items: center;
    }
    .menu-icon-btn .MuiButton-root {
        display: inherit;
        margin-left: auto;
        margin-right: 30px;
        color: var(--main-brown);
        border-color: var(--main-brown);
    }
    .content {
        margin-left: 0;
    }
    .material-table .MuiToolbar-root {
        display: block;
    }
    .material-table .MuiInputBase-input {
        font-size: 0.7rem;
    }
    .material-table .MuiSvgIcon-fontSizeSmall {
        font-size: 1rem;
    }
    .table-footer.MuiGrid-root {
        margin-top: 1rem;
        padding-left: 0;
    }
    .table-footer.table-dashboard.MuiGrid-root {
        padding-left: 5px;
    }
    .MuiTableFooter-root .MuiTablePagination-input .MuiSelect-select.MuiSelect-select {
        padding-left: 0;
        padding-right: 15px;
        font-size: 0.8rem;
        width: auto;
    }
    .MuiTableFooter-root .MuiTablePagination-input .MuiSelect-icon {
        width: 0.8em;
    }
    .MuiTableFooter-root [class*="MTablePaginationInner-root"] .MuiIconButton-root {
        padding: 5px;
    }
    .MuiTableFooter-root .MuiTableCell-footer .MuiToolbar-gutters {
        overflow: hidden;
    }
    .material-table .MuiTableFooter-root .MuiTablePagination-input {
        display: flex;
    }
    .material-table .MuiToolbar-root [class*="MTableToolbar-searchField"],
    .material-table .MuiToolbar-root [class*="jss"].MuiFormControl-root {
        padding: 0;
        padding-bottom: 1rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: min-content;
    }
    .material-table .MuiToolbar-root [class*="MTableToolbar-searchField"]
        .MuiInputAdornment-positionEnd .MuiIconButton-root,
    .material-table .MuiToolbar-root [class*="jss"].MuiFormControl-root .MuiInputAdornment-positionEnd .MuiIconButton-root {
        padding-left: 0;
    }
    .material-table .MuiToolbar-root .MuiTypography-h6 {
        font-size: 1.1rem;
        padding: 1rem 0;
        text-align: center;
    }
    .registered-table .MuiToolbar-root [class*="MTableToolbar-searchField"],
    .registered-table .MuiToolbar-root [class*="jss"].MuiFormControl-root {
        width: min-content;
        padding-left: 0;
    }
    .registered-table .MuiToolbar-root .MuiTypography-h6 {
        font-size: 1rem;
        text-overflow: initial !important;
    }
    .download-btn.MuiButton-root {
        width: 100%;
        margin-left: 0;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .grid-container {
        padding: 30px 30px;
    }
    .responsive-header h2 {
        display: inherit;
    }
    .responsive-header.MuiGrid-container {
        color: var(--main-brown);
        background-color: #343A40;
    }
    .responsive-header h2 {
        margin-left: 30px;
    }
    .menu-icon-btn.MuiGrid-item {
        display: flex;
        align-items: center;
    }
    .menu-icon-btn .MuiButton-root {
        display: inherit;
        margin-left: auto;
        margin-right: 30px;
        color: var(--main-brown);
        border-color: var(--main-brown);
    }
    .content {
        margin-left: 0;
    }
    .material-table .MuiToolbar-root {
        display: block;
    }
    .material-table .MuiInputBase-input {
        font-size: 1rem;
    }
    .material-table .MuiSvgIcon-fontSizeSmall {
        font-size: 1.25rem;
    }
    .table-footer.MuiGrid-root {
        margin-top: 1rem;
        padding-left: 0;
    }
    .table-footer.table-dashboard.MuiGrid-root {
        padding-left: 7px;
    }
    .MuiTableFooter-root .MuiTablePagination-input .MuiSelect-select.MuiSelect-select {
        font-size: 0.9rem;
        width: 100%;
        padding-left: 5px;
        padding-top: 1rem;
        text-align: left;
        text-align-last: left;
    }
    .MuiTableFooter-root .MuiTablePagination-input .MuiSelect-icon {
        width: 1.2em;
    }
    .MuiTableFooter-root [class*="MTablePaginationInner-root"] .MuiIconButton-root {
        padding: 5px;
    }
    .MuiTableFooter-root .MuiTableCell-footer .MuiToolbar-gutters {
        overflow: hidden;
    }
    .material-table .MuiTableFooter-root .MuiTablePagination-input {
        display: flex;
    }
    .material-table .MuiToolbar-root [class*="MTableToolbar-searchField"],
    .material-table .MuiToolbar-root [class*="jss"].MuiFormControl-root {
        padding: 0;
        padding-bottom: 1rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: max-content;
    }
    .material-table .MuiToolbar-root [class*="MTableToolbar-searchField"]
        .MuiInputAdornment-positionEnd .MuiIconButton-root,
    .material-table .MuiToolbar-root [class*="jss"].MuiFormControl-root
        .MuiInputAdornment-positionEnd .MuiIconButton-root {
        padding-left: 0;
    }
    .material-table .MuiToolbar-root .MuiTypography-h6 {
        padding: 1rem 0;
        text-align: center;
    }
}

@media (max-width: 767px) and (orientation: landscape) {
    .material-table .MuiToolbar-root {
        display: flex;
    }
    .material-table .MuiToolbar-root .MuiTypography-h6 {
        font-size: 1rem;
        padding: 0;
        text-align: left;
        padding-left: 1rem;
    }
    .material-table .MuiToolbar-root [class*="MTableToolbar-searchField"],
    .material-table .MuiToolbar-root [class*="jss"].MuiFormControl-root {
        width: min-content;
        padding-right: 1rem;
        padding-bottom: 0;
    }
    .MuiTableFooter-root .MuiTablePagination-input .MuiSelect-select.MuiSelect-select {
        padding-top: 6px;
    }
    .table-footer.MuiGrid-root {
        margin-top: -44px;
        padding-left: 15px;
    }
    .table-footer.table-dashboard.MuiGrid-root {
        padding-left: 5px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .content {
        margin-left: 200px;
    }
    .grid-container {
        padding: 35px;
    }
    .material-table .MuiToolbar-root .MuiTypography-h6 {
        font-size: 1rem;
        white-space: normal !important;
    }
    .table-footer.MuiGrid-root {
        margin-top: 1.2rem;
        padding-left: 0;
    }
    .table-footer.table-dashboard.MuiGrid-root {
        padding-left: 6px;
    }
    .material-table [class*="Component-horizontalScrollContainer"] div:nth-child(1) div:nth-child(1) {
        max-height: max-content !important;
        min-height: max-content !important;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .material-table .MuiToolbar-root .MuiTypography-h6 {
        font-size: 1.15rem;
    }
    .material-table [class*="Component-horizontalScrollContainer"] div:nth-child(1) div:nth-child(1) {
        max-height: max-content !important;
        min-height: max-content !important;
    }
}

@media (min-width: 1600px) {
    .table-footer.table-dashboard.MuiGrid-root {
        padding-left: 2%;
    }
}