/* Style for form in Modal */
.custom-form .MuiFormControl-root {
    display: block;
    margin-bottom: 20px;
}
.modal-cancel-btn {
    float: right;
}
.modal-body {
    top: 50%;
    left: 50%;
    width: 30vw;
    position: absolute;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 3px 3px 3px black;
    padding: 20px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
}

/* Style for form layout */
.add-form {
    background-color: white;
    padding: 1rem 3rem 3rem;
    border-radius: 5px;
}

/* Style for error on validation */
.error-style .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
}
.error-style .MuiOutlinedInput-notchedOutline {
    border-color: red;
}

/* Style for half-width input field */
.form-left-field {
    width: 97%;
    float: left;
    margin-bottom: 20px !important;
}
.form-right-field {
    width: 97%;
    float: right;
    margin-bottom: 20px !important;
}

/* Style for selection label */
.tf-label label {
    padding: 0 3px;
    background-color: white;
}

/* Style for image uploader */
.browse-btn {
    width: 25%;
    display: inline-block;
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    border: solid 1px darkgray;
    background-color: #CE9963;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.browse-btn:hover {
    background-color: burlywood;
}
.img-name {
    width: 62%;
    display: inline-grid;
    padding: 10px 5px;
    border: solid 1px darkgray;
    white-space: nowrap;
    overflow: clip;
    text-overflow: ellipsis;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.image-info {
    padding-left: 10px;
}
.delete-img-btn {
    position: relative;
}
.delete-img-btn .MuiSvgIcon-root {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #F0F0F0;
}
.can-delete {
    display: initial !important;
    cursor: pointer;
}
.cannot-delete {
    display: none !important;
    cursor: default;
}

/* Style for grouped field */
.grouped-field [class*="control"] {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}
.grouped-field [class*="control"]:hover {
    border-color: #592c0d !important;
}
.grouped-field [class*="placeholder"] {
    color: #592c0d77;
}
.grouped-field [class*="control"] [class*="ValueContainer"] [class*="multiValue"] {
    border-radius: 15px;
    padding: 3px 6px;
    font-size: 0.9rem;
}
.grouped-field [class*="menu"] {
    z-index: 101;
}

.grouped-label {
    font-size: 0.78rem;
    color: rgba(0, 0, 0, 0.6);
    background-color: white;
    margin-top: -10px;
    position: absolute;
    margin-left: 7px;
    padding: 0 6px;
    left: 0;
    z-index: 100;
}
.grouped-label.half-right-label {
    margin-left: 1.5rem;
}
.hide-label {
    display: none;
}

/* Style for disabled dropdown field */
.disabled-field {
    background: none !important;
}
.disabled-field [class*="control"] {
    cursor: default;
    pointer-events: none;
}
.disabled-field:hover, .disabled-field [class*="control"]:hover {
    border-color:rgba(0, 0, 0, 0.22) !important;
}
.disabled-field [class*="multiValue"] svg,
.disabled-field [class*="chipContainer"] .MuiChip-deleteIcon {
    display: none;
}

/* Style for input image */
.full-input-image .image-info {
    width: 45%;
    margin-left: auto;
    margin-right: auto;
}
.half-input-image {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.half-input-image .delete-img-btn {
    right: 15px;
}
.half-input-image .image-info {
    padding-left: 15px;
}
.img-div {
    max-width: 10rem;
    height: 10rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    aspect-ratio: 1/1;    
}
.img-div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    min-height: 10rem;
}
.a4-size .img-div,
.a4-size .modal-image-div {
    aspect-ratio: 3/4;
}
.three-two-size .img-div,
.three-two-size .modal-image-div {
    aspect-ratio: 3/2;
}
.eight-three-size .img-div,
.eight-three-size .modal-image-div {
    aspect-ratio: 8/3;
}
.gallery-section .eight-three-size img {
    width: 100% !important;
    height: 100% !important;
}
.a4-size .img-div img,
.a4-size .modal-image-div img,
.three-two-size .img-div img,
.three-two-size .modal-image-div img,
.eight-three-size .img-div,
.eight-three-size .modal-image-div {
    object-fit: contain;
}

/* Style for radio button & choicebox */
.rb-layout, .cb-layout {
    border: solid 1px;
    border-color: rgba(0, 0, 0, 0.28);
    padding: 5px 15px;
    border-radius: 5px;
    margin-bottom: 3px;
}
.rb-layout:hover, .cb-layout:hover {
    border-color: black;
}
.rb-label, .cb-label {
    display: contents !important;
    font-size: 0.8em !important;
}
.cb-layout .MuiGrid-item .MuiFormControlLabel-root {
    display: block;
}
.cb-item .MuiFormGroup-root .MuiFormControlLabel-root {
    cursor: default;
    pointer-events: none;
}
.cb-item .MuiFormGroup-root .MuiFormControlLabel-root .MuiButtonBase-root {
    pointer-events: initial;
}
.cb-item .MuiFormGroup-root .MuiFormControlLabel-root.bold-label .MuiTypography-root {
    font-weight: bold;
}
.cb-item .MuiFormGroup-root .MuiFormControlLabel-root.label-on-start {
    width: 100%;
    margin-left: 0;
}
.cb-item .MuiFormGroup-root .MuiFormControlLabel-root.label-on-start .MuiTypography-root {
    margin-right: auto;
}
.MuiCheckbox-colorSecondary.Mui-disabled {
    background: none !important;
}

/* Style for gallery section in form */
.gallery-section {
    border: solid 1px lightgray;
    border-radius: 5px;
    padding: 15px 0 5px;
}
.gallery-section:hover {
    border-color: black;
}
.gallery-section h2 {
    text-align: center;
    margin-top: 0;
}

/* Style for switch / toggle form */
.MuiFormControl-root.switch-layout {
    border: solid 1px;
    border-color: rgba(0, 0, 0, 0.28);
    border-radius: 5px;
    padding: 8px 0;
    width: 100%;
}
.MuiFormControl-root.switch-layout:hover {
    border-color: black;
}
.MuiFormLabel-root.legend-switch {
    margin-left: 10px;
    font-size: 0.8em;
}
.MuiFormControlLabel-root.fcl-switch {
    padding-left: 10px;
}

/* Style for modal image */
.modal-image-div {
    max-width: 10rem;
    height: 10rem;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    aspect-ratio: 1/1;
}
.modal-image-div div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    min-height: 10rem;
}

/* Style for member info section */
.member-info {
    border: solid 1px;
    border-color: rgba(0, 0, 0, 0.28);
    border-radius: 5px;
    margin-bottom: 25px;
    padding: 15px;
    padding-top: 25px;
}
.data-not-found-btn.MuiButton-root {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    background-color: #CE9963;
    font-weight: bold;
}
.data-not-found-btn.MuiButton-root:hover {
    background-color: burlywood;
}

/* Style for member card ID text */
.member-id-text {
    margin: 0 3px 30px;
}

/* Style for Add & Remove button in Gallery Section */
.add-new-gallery, .MuiButton-root.add-new-gallery {
    height: 75px;
    width: 75px;
    display: block;
    margin: 100px auto;
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
    border-radius: 50%;
    background-color: #CE9963;
    box-shadow: 1px 1px 4px black;
}
.MuiButton-root.add-gallery-null {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    font-weight: bold;
    background-color: #CE9963;
}
.add-new-gallery:hover,
.MuiButton-root.add-gallery-null:hover,
.MuiButton-root.add-new-gallery:hover {
    background-color: burlywood;   
    cursor: pointer;
}
.MuiButton-root.delete-btn-gallery {
    margin: 8px auto 0;
    display: block;
    width: 160px;
}

/* Style for input image conditions */
.input-img-terms {
    margin-top: 5px;
}

/* Style for subscription year input number */
.subscription-year-layout {
    display: flex;
}
.MuiFormControl-root.subscription-year-input {
    margin: -2px 5px 20px 10px;
}
.subscription-year-input .MuiInputBase-root .MuiInputBase-input {
    width: 40px;
    padding: 5px 5px 5px 15px;
}

/* Style for input field pin */
.full-width.pin-field .MuiInputBase-root {
    width: 100%;
}

/* Style for remaining member info */
.remaining-registered.MuiGrid-item {
    text-align: right;
    display: grid;
    align-items: center;
}
.remaining-registered h3 {
    margin: 0;
    margin-bottom: 6px;
}


/* Generate Pin Style */
.generate-pin-notes {
    margin-bottom: -10px;
}

/* Copy Clipboard Result Area */
.MuiFormControl-root.copy-clipboard-area {
    width: 75%;
}
.copy-clipboard-area .MuiOutlinedInput-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.copy-clipboard-area .MuiInputBase-inputMultiline {
    height: 4.5rem;
    line-height: 1.3rem;
}
.MuiButton-root.generate-btn {
    font-family: 'Verdana';
    font-weight: bold;
    width: 25%;
    padding: 16px 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
}
.MuiButton-root.generate-btn.copy {
    padding: 43px 14px;
    background-color: var(--main-brown);
}
.MuiButton-root.generate-btn:hover {
    box-shadow: none;
    background-color: burlywood;
}
.generate-result {
    width: 74.5%;
    display: inline-block;
    border: solid 1px rgba(0, 0, 0, 0.28);
    padding: 18.5px 14px;
}
.generate-result .link-result {
    width: 100%;
    display: block;
    box-sizing: content-box;
}

.character-counter {
    display: block;
    margin-bottom: 20px;
    margin-top: -22px;
    text-align: right;
    font-size: 14px;
}

@media (max-width: 479px) {
    .add-form {
        padding: 1rem 1.5rem 3rem;
    }
    .form-right-field, .form-left-field {
        width: 100%;
    }
    .full-input-image .image-info {
        width: 100%;
    }
    .browse-btn {
        width: 27%;
    }
    .text-required-half.half-right {
        margin-left: 0;
    }
    .grouped-label.half-right-label {
        margin-left: 0.5rem;
    }
    .gallery-section h2 {
        font-size: 1.2rem;
    }
    .half-input-image {
        width: 100%;
    }
    .half-input-image .browse-btn {
        width: 25%;
    }
    [class*="PrivateRadioButtonIcon-root"] .MuiSvgIcon-root {
        font-size: 1rem;
    }
    .rb-layout .MuiFormControlLabel-root [class*="MuiTypography-body"] {
        font-size: 0.8rem;
    }
    .cb-item .MuiFormGroup-root .MuiFormControlLabel-root [class*="PrivateSwitchBase-root"] {
        padding-right: 5px;
    }
    .cb-item .MuiFormGroup-root .MuiButtonBase-root .MuiIconButton-label .MuiSvgIcon-root {
        font-size: 1.1rem;
    }
    .cb-layout .MuiFormControlLabel-root [class*="MuiTypography-body"] {
        font-size: 0.8rem;
    }
    .cb-layout div:nth-child(2) {
        padding-left: 0 !important;
    }
    .custom-form .grouped-label.half-right-label {
        margin-left: 1.8rem;
    }
    .modal-body {
        width: 60vw;
    }
    #simple-modal-title {
        margin-top: 0;
        margin-bottom: 1.5rem !important;
        font-size: 1.2rem;
        text-align: center;
    }
    .add-btn-table.MuiButton-root {
        background-color: #CE9963;
    }
    .subscription-year-layout {
        margin-bottom: 1rem;
    }
    .MuiGrid-item.vote-result-layout h3 {
        margin: 0 10px;
        font-size: 1rem;
    }
    .MuiGrid-item.vote-result-layout h1 {
        font-size: 2rem !important;
        margin-top: 1rem !important;
    }
    .edit-btn.MuiButton-root {
        float: none;
        display: block;
        margin: -5px auto 2rem !important;
        min-width: 100%;
    }
    .secondary-btn.MuiButton-root {
        margin-bottom: 1.5rem !important;
    }
    .responsive-submit-btn.MuiButton-root {
        float: left !important;
        margin-right: 0 !important;
    }
    .input-img-terms {
        text-align: center;
    }
    .center-responsive {
        text-align: center;
    }
    .remaining-registered.MuiGrid-item {
        text-align: center;
        margin: 0 0 2rem;
    }
}

@media (min-width: 480px) and (max-width: 959px) {
    .remaining-registered.MuiGrid-item {
        text-align: left;
        margin: -1.2rem 0 2rem;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .add-form {
        padding: 1rem 2rem 3rem;
    }
    .form-right-field, .form-left-field {
        width: 100%;
    }
    .full-input-image .image-info {
        width: 80%;
    }
    .browse-btn {
        width: 30%;
    }
    .text-required-half.half-right {
        margin-left: 0;
    }
    .cb-layout div:nth-child(2) {
        padding-left: 0 !important;
    }
    .edit-btn.MuiButton-root {
        float: none;
        display: block;
        margin: -5px auto 2rem;
    }
    .edit-btn.prev-btn.MuiButton-root {
        min-width: 49%;
    }
    .edit-btn.next-btn.MuiButton-root {
        min-width: 49%;
        float: right !important;
    }
    .responsive-submit-btn.MuiButton-root {
        float: left !important;
    }
    .subscription-year-layout {
        margin-bottom: 1rem;
    }
    #simple-modal-title {
        margin-top: 0;
        margin-bottom: 1.5rem !important;
        font-size: 1.5rem;
        text-align: center;
    }
    .add-btn-table.MuiButton-root {
        background-color: #CE9963;
    }
    .edit-btn.MuiButton-root {
        float: none;
        display: block;
        margin: -5px auto 2rem !important;
        min-width: 100%;
    }
    .secondary-btn.MuiButton-root {
        margin-bottom: 1.5rem !important;
    }
    .responsive-submit-btn.MuiButton-root {
        float: left !important;
        margin-right: 0 !important;
    }
    .input-img-terms {
        text-align: center;
    }
    .center-responsive {
        text-align: center;
    }
    .modal-body {
        width: 50vw;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .add-form {
        padding: 1rem 2rem 3rem;
    }
    .form-right-field, .form-left-field {
        width: 100%;
    }
    .full-input-image .image-info {
        width: 80%;
    }
    .browse-btn {
        width: 30%;
    }
    .text-required-half.half-right {
        margin-left: 0;
    }
    .cb-layout div:nth-child(2) {
        padding-left: 0 !important;
    }
    #simple-modal-title {
        margin-top: 0;
        margin-bottom: 1.5rem !important;
    }
    .add-btn-table.MuiButton-root {
        background-color: #CE9963;
    }
    .edit-btn.MuiButton-root {
        float: none;
        display: block;
        margin: -5px auto 2rem !important;
        min-width: 100%;
    }
    .edit-btn.prev-btn.MuiButton-root {
        min-width: 49%;
    }
    .edit-btn.next-btn.MuiButton-root {
        min-width: 49%;
        float: right !important;
    }
    .secondary-btn.MuiButton-root {
        margin-bottom: 1.5rem !important;
    }
    .responsive-submit-btn.MuiButton-root {
        float: left !important;
        margin-right: 0 !important;
    }
    .modal-body {
        width: 40vw;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .full-input-image .image-info {
        width: 80%;
    }
    .browse-btn {
        width: 30%;
    }
    .grouped-label.half-right-label {
        margin-left: 1.2rem;
    }
    .img-name {
        width: 60%;
    }
    .rb-layout div:nth-child(2) .MuiFormControlLabel-root {
        margin-left: 10px !important;
    }
    [class*="PrivateRadioButtonIcon-root"] .MuiSvgIcon-root {
        font-size: 1.1rem;
    }
    .cb-item .MuiFormGroup-root .MuiFormControlLabel-root [class*="PrivateSwitchBase-root"] {
        padding-right: 5px;
    }
    .cb-item .MuiFormGroup-root .MuiButtonBase-root .MuiIconButton-label .MuiSvgIcon-root {
        font-size: 1.1rem;
    }
    .cb-layout .MuiFormControlLabel-root [class*="MuiTypography-body"] {
        font-size: 0.9rem;
    }
    .edit-btn.MuiButton-root {
        margin-top: 1.8rem;
        font-size: 0.8rem;
        padding: 5px 10px;
    }
    .add-btn-table.MuiButton-root {
        font-size: smaller;
    }
    /* .gallery-section .MuiGrid-item div img {
        width: 9rem !important;
        height: 9rem !important;
    } */
    .gallery-section .MuiGrid-item label .add-new-gallery {
        margin: 75px auto;
    }
    .gallery-section .MuiButton-root.delete-btn-gallery {
        width: 9rem;
    }
}

/* @media (width: 320px) { .full-input-image .delete-img-btn { right: -35px; } }
@media (width: 360px) { .full-input-image .delete-img-btn { right: -15px; } }
@media (min-width: 375px) and (max-width: 385px) { .full-input-image .delete-img-btn { right: -10px; } }
@media (min-width: 400px) and (max-width: 415px) { .full-input-image .delete-img-btn { right: 10px; } }
@media (width: 600px) { .full-input-image .delete-img-btn { right: 95px; } }
@media (width: 768px) { .full-input-image .delete-img-btn { right: 75px; } }
@media (width: 800px) { .full-input-image .delete-img-btn { right: 90px; } }
@media (width: 834px) { .full-input-image .delete-img-btn { right: 110px; } }
@media (min-width: 835px) and (max-width: 1023px) { .full-input-image .delete-img-btn { right: 100px; } }
@media (min-width: 1024px) and (max-width: 1160px) { .full-input-image .delete-img-btn { right: 120px; } }
@media (min-width: 1161px) and (max-width: 1280px) { .full-input-image .delete-img-btn { right: 250px; } } */