/* Style for dashboard's cards */
/* .dashboard-card.MuiGrid-grid-lg-2 {
    max-width: 20%;
    flex-basis: 20%;
} */
.card-layout {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    transform: scale(0.95);
    filter: brightness(0.9);
}
.hover-bright {
    filter: brightness(1);
    transition: transform 1s;
}
.hover-bright:hover {
    filter: brightness(1.1);
}
.card-header {
    background-color: #CE9963;
    text-align: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    min-height: 4rem;
}
.card-header span {
    font-weight: bold;
    font-size: 1.1rem;
}
.card-content {
    text-align: center;
    font-size: 1.2rem;
    padding: 0 !important;
}

/* Style for table in dashboard */
.registered-table .MuiTableSortLabel-root {
    width: max-content;
}

/* Style for birthday filter section */
.MuiGrid-item.birthday-filter-layout {
    background-color: white;
    border-radius: 5px;
    margin: 0 1.2%;
    padding: 30px;
}

/* Style for vote result section */
.MuiGrid-item.vote-result-layout {
    background-color: white;
    padding: 2rem;
    margin: 0 1.2%;
    border-radius: 5px;
}
.MuiGrid-item.vote-result-layout h1,
.MuiGrid-item.vote-result-layout h2,
.MuiGrid-item.vote-result-layout h3 {
    text-align: center;
    margin-top: 0;
}

/* Style for charts */
.chart-layout {
    padding: 0 1.2%;
}
.chart-layout .chartjs-render-monitor {
    background-color: white;
}
.chart-title {
    text-align: center;
    margin-top: 0;
}

/* Style for vote result */
.vote-result-card-2.MuiGrid-item {
    margin: 1rem 1% 0;
    max-width: 48%;
    border: solid 2px black;
    border-radius: 5px;
}
.vote-result-card-3.MuiGrid-item {
    margin: 1rem 1.1% 0;
    max-width: 31%;
    border: solid 2px black;
    border-radius: 5px;
}
.vote-result-card-2.MuiGrid-item .vote-card-header,
.vote-result-card-3.MuiGrid-item .vote-card-header {
    padding: 1rem 0;
    color: #CE9963;
    background-color: #343A40;
}
.vote-result-photo {
    display: block;
    margin: 2rem auto 1rem;
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: center;
}
.overall-vote-subtitle {
    width: 80%;
    min-height: 80px;
    margin-left: auto;
    margin-right: auto;
}
.vote-right-border.MuiGrid-grid-md-6,
.vote-right-border.MuiGrid-grid-lg-4 {
    border-right: solid 1px black;
}

/* Voters Button */
.voters-container.MuiGrid-item {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
}
.voters-container .btn-color {
    background-color: var(--main-brown);
}
.voters-container .btn-color:hover {
    background-color: burlywood;
}

@media (max-width: 479px) {
    .grid-container h1 {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 2rem;
    }
    .grid-container h1.card-icon {
        margin-bottom: 1.2rem;
    }
    .card-header span {
        font-size: 0.9rem;
    }
    .card-header {
        min-height: 3rem;
    }
    .vote-result-card-3.MuiGrid-item {
        max-width: initial;
    }
    .MuiGrid-item.vote-result-layout h1 {
        font-size: 2.5rem !important;
    }
    .birthday-filter-layout h2 {
        text-align: center;
    }
    .vote-result-card-3.MuiGrid-item {
        border: none;
    }
    .voters-container .MuiButton-root {
        display: block;
        width: 100%;
        padding-top: 10px;
    }
    .vote-result-card-2.MuiGrid-item {
        max-width: none;
    }
    .vote-result-photo {
        width: 120px;
        height: 120px;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .vote-result-card-3.MuiGrid-item {
        max-width: initial;
        margin: 0;
        border: none;
    }
    .vote-result-card-2.MuiGrid-item {
        max-width: none;
    }
    .vote-result-photo {
        width: 160px;
        height: 160px;
    }
}

@media (max-width: 767px) and (orientation: landscape) {
    .vote-result-card-3.MuiGrid-item {
        max-width: 47.7%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .grid-container h1 {
        font-size: 1.5rem;
    }
    .grid-container h1.card-icon {
        font-size: 1rem;
    }
    .card-header span {
        font-size: 1rem;
    }
    .card-header {
        min-height: 3.2rem;
    }
    .vote-result-card-3.MuiGrid-item {
        max-width: 47.7%;
        margin-top: 0.7rem;
    }
    .MuiGrid-item.vote-result-layout h2 {
        font-size: 1.5rem;
    }
    .vote-result-photo {
        width: 150px;
        height: 150px;
    }
    .MuiGrid-item.vote-result-layout h1 {
        font-size: 2.5rem !important;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .vote-result-photo {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 960px) {
    .pie-vote-container.MuiGrid-item {
        margin-top: -20px;
        margin-bottom: 30px;
    }
    .pie-vote-container .chartjs-render-monitor {
        max-height: 240px;
        width: auto !important;
        margin-left: auto;
        margin-right: auto;
    }
}